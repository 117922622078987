import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import type {
  RequireAtLeastOne,
  SnakeCasedProperties,
  SnakeCasedPropertiesDeep,
} from 'type-fest';
import type {
  Document,
  DocumentSplitSuggestionResponse,
  FillConfig,
  FormMatch,
} from 'src/types/proto/documents';
import type { FormConfig } from 'src/types/proto/reform';
import type {
  SearchFormPublicResponse,
  SearchFormRequest,
  SearchFormResponse,
} from 'src/types/proto/services/document_form_public_service';
import type {
  CreateDocumentRequest,
  GetFormSuggestionsRequest,
  GetFormsByGfpFlowIdResponse,
  UploadDocumentsResponse,
} from 'src/types/proto/services/document_public_service';
import type {
  GetDocumentByUrlRequest,
  GetEditableDocumentByTdvRequest,
  GetEditableDocumentByTdvResponse,
  PspdfkitDocument,
} from 'src/types/proto/services/pspdfkit_public_service';
import type { ZipTransactionDocumentVersionsRequest } from 'src/types/proto/services/transaction_public_service';
import type { ApiResponseData } from 'src/types/utils';
import { downloadBlob } from 'src/utils/download-blob';
import { formatToSnakeCase } from 'src/utils/format-data-object';
import BaseApi from '../base-api';
import type { ZipFileOptions } from './shared-packet';

export interface UploadOptions {
  asPDF?: boolean;
}

export default class Documents extends BaseApi {
  createDocument(
    file: Omit<CreateDocumentRequest, 'analyze' | 'decrypt'>,
    analyze = false,
    decrypt = false
  ) {
    return this.post<Document>('/documents', {
      url: file.url,
      filename: file.filename,
      byteSize: file.byteSize,
      thumbnailUrl: file.thumbnailUrl,
      analyze,
      decrypt,
    });
  }

  searchForms = ({
    query,
    libraryUuids,
    state,
    county,
  }: RequireAtLeastOne<SearchFormRequest, 'libraryUuids' | 'state'>) => {
    return this.get<
      ApiResponseData<SearchFormResponse | SearchFormPublicResponse>
    >('/documents/forms/search', {
      params: {
        query,
        ...(libraryUuids
          ? {
              library_uuids: libraryUuids,
            }
          : null),
        ...(state
          ? {
              state,
              county,
            }
          : null),
      },
    });
  };

  getBlobByDocToken(docToken: string) {
    return this.get<Blob>('/documents/download_by_doc_token', {
      responseType: 'blob',
      params: formatToSnakeCase({
        docToken,
      }),
    });
  }

  async downloadByDocToken(docToken: string, filename: string) {
    const blob = await this.getBlobByDocToken(docToken);
    downloadBlob(blob, filename);
  }

  getFlowAttachmentBlob(flowId: string, documentId: string) {
    return this.get<Blob>(`/documents/flow/${flowId}/download_attachment`, {
      responseType: 'blob',
      params: formatToSnakeCase({
        documentId,
      }),
    });
  }

  async downloadFlowAttachment(
    flowId: string,
    documentId: string,
    filename: string
  ) {
    const blob = await this.getFlowAttachmentBlob(flowId, documentId);
    downloadBlob(blob, filename);
  }

  getBlobByTdId(tdId: string) {
    return this.get<Blob>('/documents/download', {
      responseType: 'blob',
      params: formatToSnakeCase({
        tdId,
      }),
    });
  }

  async downloadByTdId(tdId: string, filename: string) {
    const blob = await this.getBlobByTdId(tdId);
    downloadBlob(blob, filename);
  }

  getThumbnailBlobByTdId(tdId: string) {
    return this.get<Blob>('/documents/thumbnail', {
      responseType: 'blob',
      params: formatToSnakeCase({
        tdId,
      }),
    });
  }

  getThumbnailBlobByTdvId(tdvId: string) {
    return this.get<Blob>('/documents/tdv/thumbnail', {
      responseType: 'blob',
      params: formatToSnakeCase({
        tdvId,
      }),
    });
  }

  getBlobByTdvId(tdvId: string) {
    return this.get<Blob>('/documents/tdv/download', {
      responseType: 'blob',
      params: formatToSnakeCase({
        tdvId,
      }),
    });
  }

  async downloadByTdvId(tdvId: string, filename: string) {
    const blob = await this.getBlobByTdvId(tdvId);
    await downloadBlob(blob, filename);
  }

  getThumbnailBlobByGfpfId(txnId: string, gfpfId: string) {
    return this.get<Blob>(`/documents/txn/${txnId}/gfpf/${gfpfId}/thumbnail`, {
      responseType: 'blob',
    });
  }

  getBlobByGfpfId(txnId: string, gfpfId: string) {
    return this.get<Blob>(`/documents/txn/${txnId}/gfpf/${gfpfId}/download`, {
      responseType: 'blob',
    });
  }

  async downloadByGfpfId(txnId: string, gfpfId: string, filename: string) {
    const blob = await this.getBlobByGfpfId(txnId, gfpfId);
    downloadBlob(blob, filename);
  }

  getZipBlobByTdIds(
    tdIds: string[],
    zipFilename: string,
    options: ZipFileOptions = {}
  ) {
    const { numberDocuments = false } = options;

    return this.get<Blob>('/documents/zip_on_the_fly', {
      responseType: 'blob',
      params: formatToSnakeCase({
        tdIds,
        output: zipFilename,
        numberDocuments,
      }),
    });
  }

  async downloadMultipleDocsAsZip(
    tdIds: string[],
    zipFilename: string,
    options: ZipFileOptions = {}
  ) {
    const blob = await this.getZipBlobByTdIds(tdIds, zipFilename, options);
    downloadBlob(blob, zipFilename);
  }

  getZipFileBlob({
    transactionId,
    tdvIds,
    filename,
  }: ZipTransactionDocumentVersionsRequest) {
    return this.get<Blob>('/transactions/zip_transaction_document_versions', {
      responseType: 'blob',
      params: {
        transactionId,
        tdvIds,
        filename,
      },
      paramsSerializer: (params) => qs.stringify(params, { indices: false }),
    });
  }

  getFormSuggestions<T extends GetFormSuggestionsRequest>({
    tdIds,
    tdvIds,
    transactionId,
    mode = 'tabbing',
  }: T) {
    type Suggestion = T['mode'] extends 'splitting'
      ? DocumentSplitSuggestionResponse
      : FormMatch;

    return this.get<Record<string, Suggestion>>(
      `/documents/suggestions/${transactionId}`,
      {
        params: {
          td_ids: tdIds && tdIds.length ? tdIds : undefined,
          tdv_ids: tdvIds && tdvIds.length ? tdvIds : undefined,
          mode,
        },
      }
    );
  }

  getGfpForms(gfpFlowId: string) {
    return this.get<ApiResponseData<GetFormsByGfpFlowIdResponse>>(
      `/documents/gfp_forms/${encodeURIComponent(gfpFlowId)}`
    );
  }

  getPspdfkitDocumentByForm(formId: string) {
    return this.get<SnakeCasedProperties<PspdfkitDocument>>(
      `/pspdfkit/document/by_form/${encodeURIComponent(formId)}`
    );
  }

  getPspdfkitDocumentByDoc(
    documentUuid: string,
    options: { isCoverSheetDoc?: boolean } = {}
  ) {
    const { isCoverSheetDoc = false } = options || {};
    return this.get<SnakeCasedProperties<PspdfkitDocument>>(
      `/pspdfkit/document/by_doc/${encodeURIComponent(documentUuid)}${
        isCoverSheetDoc ? '?cover-sheet-doc=true' : ''
      }`
    );
  }

  getPspdfkitDocumentByUrl(
    url: string,
    options: { isCoverSheetDoc?: boolean } = {}
  ) {
    const { isCoverSheetDoc = false } = options || {};
    return this.post<SnakeCasedProperties<PspdfkitDocument>>(
      `/pspdfkit/document/by_url${
        isCoverSheetDoc ? '?cover-sheet-doc=true' : ''
      }`,
      {
        url,
      } as GetDocumentByUrlRequest
    );
  }

  getFillConfigByFormUuid(formUuid: string, tdId?: string) {
    return this.get<FillConfig>(
      `/forms/${encodeURIComponent(formUuid)}/fill_config`,
      tdId
        ? {
            params: {
              td_id: tdId,
            },
          }
        : undefined
    );
  }

  uploadFileProxied(
    formData: FormData,
    options: UploadOptions = {},
    config?: AxiosRequestConfig
  ) {
    return this.post<UploadDocumentsResponse>('/documents/upload', formData, {
      params: formatToSnakeCase(options),
      ...config,
    });
  }

  getPspdfkitEditableDocumentByTdv(transactionId: string, tdvId: string) {
    return this.post<
      SnakeCasedPropertiesDeep<GetEditableDocumentByTdvResponse>
    >(
      '/pspdfkit/document/editable_by_tdv',
      formatToSnakeCase({
        transactionId,
        tdvId,
      } as GetEditableDocumentByTdvRequest)
    );
  }

  getFormConfig(formUuid: string) {
    return this.get<FormConfig>(
      `/forms/${encodeURIComponent(formUuid)}/form_config`
    );
  }

  revokeEmailedDocumentsAccess(emailId: string) {
    return this.post<unknown>('/documents/revoke', { emailId });
  }
}
